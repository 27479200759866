import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Tooltip,
  Avatar,
  Modal,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import {
  CustomInputLabel,
  CustomProductSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import { NoDataImage } from "../IconsImport";
import { PhotoProvider, PhotoView } from "react-photo-view";

export default function InfluencerorderViewDetails({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {

  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "adminnote", label: "Current Note", minWidth: 200 },
    { id: "status", label: " Current Status,", minWidth: 200 },
    { id: "updatedat", label: "Updated Date", minWidth: 200 },
    { id: "updatedby", label: "Updated By", minWidth: 200 },
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };

  // const realData = rowData?.adminNoteHistory.map((item, index) => ({
  //   sno: index + 1,

  //   adminnote: item.adminNote && item.adminNote,
  //   status: item.status && item.status?.status,
  //   updatedat:
  //     item.updatedAt && new Date(item.updatedAt).toISOString().split("T")[0],
  //   updatedby: item.updatedBy?.name,
  // }));

  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  const list = (
    <Box
      // sx={{ width: showDetails ? 500 : 1000 }}
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>Order Details</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>
          {/* <div style={{ textAlign: "end", marginBottom: "20px" }}>
              <Tooltip placement="top" title="View Details">
                <VisibilityIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
             
                />
              </Tooltip>
       
          </div> */}
          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
              <>
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Brand Name</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.brand && rowData?.brand.brandName}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Campaign Name</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.campaign.campaignName}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Marqfluencer Name</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.influencer && rowData?.influencer.influencerName}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Product Name</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.product && rowData?.product.productName}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Order Platform</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.orderPlatform.platform}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Order Id</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.orderId}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Order Value</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.orderValue}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Extra Payout</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.extraPayout}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Reimbursement Value</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {rowData?.reimbursementValue}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                  <div>
                    <CustomInputLabel>Order Date</CustomInputLabel>
                  </div>
                  <CustomProductSubheadingh>
                    {new Date(rowData?.orderDate).toISOString().split("T")[0]}
                  </CustomProductSubheadingh>
                </Grid>
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
                {rowData?.deliveryDate && (
                  <>
                    <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                      <div>
                        <CustomInputLabel>
                          Delivery Date(Estimate)
                        </CustomInputLabel>
                      </div>
                      <CustomProductSubheadingh>
                        {
                          new Date(rowData?.deliveryDate)
                            .toISOString()
                            .split("T")[0]
                        }
                      </CustomProductSubheadingh>
                    </Grid>
                  </>
                )}
                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

                {rowData?.orderScreenshot.length > 0 && (
                  <Grid container>
                    <Grid item xs={12} sm={12}>
                      <CustomInputLabel>Order Screenshots</CustomInputLabel>
                    </Grid>
                    <PhotoProvider>
                      {rowData?.orderScreenshot.length > 0 &&
                        rowData?.orderScreenshot?.map((screenshot, index) => (
                          <Grid
                            item
                            xs={3}
                            sm={2}
                            key={index}
                            sx={{ paddingBottom: "15px", marginRight: "20px" }}
                          >
                            <PhotoView src={screenshot.imageUrl}>
                              <img
                                style={{
                                  width: "100%",
                                  height: "auto",
                                  border: "2px solid #163e6e",
                                  borderRadius: "10px",
                                }}
                                src={screenshot.imageUrl}
                                alt={`Order Screenshot ${index + 1}`}
                              />
                            </PhotoView>
                          </Grid>
                        ))}{" "}
                    </PhotoProvider>
                  </Grid>
                )}
                {
                !(rowData?.reimbursed === "No" && rowData?.influencerStatus?.status === "Reimbursed") && (

                    <>
                      {rowData?.reviewScreenshot.length > 0 && (
                        <>
                          <Divider
                            sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                          />
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <CustomInputLabel>
                                Review Screenshotsss
                              </CustomInputLabel>
                            </Grid>
                            <PhotoProvider>
                              {rowData?.reviewScreenshot.map(
                                (screenshot, index) => (
                                  <Grid
                                    item
                                    xs={3}
                                    sm={2}
                                    key={index}
                                    sx={{
                                      paddingBottom: "15px",
                                      marginRight: "20px",
                                    }}
                                  >
                                    <PhotoView src={screenshot.imageUrl}>
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                          border: "2px solid #163e6e",
                                          borderRadius: "10px",
                                        }}
                                        src={screenshot.imageUrl}
                                        alt={`Review Screenshot ${index + 1}`}
                                      />
                                    </PhotoView>
                                  </Grid>
                                )
                              )}
                            </PhotoProvider>
                          </Grid>
                        </>
                      )}

                      {rowData?.deliveredScreenshot.length > 0 && (
                        <>
                          <Divider
                            sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                          />
                          <Grid container>
                            <Grid item xs={12} sm={12}>
                              <CustomInputLabel>
                                Delivered & Product Screenshots
                              </CustomInputLabel>
                            </Grid>
                            <PhotoProvider>
                              {rowData?.deliveredScreenshot.map(
                                (screenshot, index) => (
                                  <Grid
                                    item
                                    xs={3}
                                    sm={2}
                                    key={index}
                                    sx={{
                                      paddingBottom: "15px",
                                      marginRight: "20px",
                                    }}
                                  >
                                    <PhotoView src={screenshot.imageUrl}>
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "auto",
                                          border: "2px solid #163e6e",
                                          borderRadius: "10px",
                                        }}
                                        src={screenshot.imageUrl}
                                        alt={`Delivered Screenshot ${
                                          index + 1
                                        }`}
                                      />
                                    </PhotoView>
                                  </Grid>
                                )
                              )}
                            </PhotoProvider>
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

                {rowData?.reelLink && (
                  <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                    <div>
                      <CustomInputLabel>
                        IG Reel/ YT Shorts/Live Review
                      </CustomInputLabel>{" "}
                    </div>
                    <CustomProductSubheadingh>
                      <a
                        href={
                          rowData.reelLink.startsWith("http")
                            ? rowData.reelLink
                            : `https://${rowData.reelLink}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline text-blue-700"
                      >
                        {rowData.reelLink}
                      </a>
                    </CustomProductSubheadingh>
                  </Grid>
                )}
              </>
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": {
              overflow: "auto",
              width: "50%",
              "@media (max-width: 600px)": {
                width: "90%",
              },
            },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
