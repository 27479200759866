import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SmallButton } from "../../component/coustombutton/SmallButton";

const NewUserOpenPopup = ({ open, navigateToHome, isSuccess }) => {
  return (
    <Modal open={open} onClose={navigateToHome}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          //   width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          //   width: "80%",
          //   maxWidth: 800,
          width: { xs: "90%", sm: "90%", md: "800px" },
          background: "#FFFFFF",
          padding: "40px",
          borderRadius: "20px",
          outline: "none",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <CloseIcon
            onClick={navigateToHome}
            sx={{
              color: "red",
              width: "30px",
              height: "30px",
              cursor: "pointer",
            }}
          />
        </div>
        <Grid
          container
          spacing={2}
          sx={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
            <div style={{ overflow: "auto", maxHeight: "70vh" }}>
              <h1 className="registerpopup_head">Important Announcement</h1>
              <p
                className="registerpopup_subhead"
                style={{ fontWeight: "600" }}
              >
                Dear Members,
      
              </p>
            
              <p className="registerpopup_subhead">
              We hope this message finds you well. We want to address some concerns that have arisen following a recent communication from a former employee. Please rest assured that we are a trusted organization committed to taking care of your orders and ensuring that your reimbursements are handled promptly and accurately.
              </p>
              <p className="registerpopup_subhead">
              The comments made by this individual do not reflect our company values or practices. We have taken appropriate actions regarding this situation, as it was an issue stemming from personal grievances rather than our organizational policies.
              </p>
              <p className="registerpopup_subhead">
              Your trust is important to us, and we are dedicated to maintaining a positive relationship with each of you. If you have any questions or concerns about your orders or reimbursements, please do not hesitate to reach out to our customer service team.
              </p>
              <p className="registerpopup_subhead">
              Please join our new community for all important announcements {" "}
                <a
                  href="https://chat.whatsapp.com/DA4ghXVJlAKGyNktTNrpRa"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "underline", color: "#163e6e" }}

                >
                  https://chat.whatsapp.com/DA4ghXVJlAKGyNktTNrpRa
                </a>{" "}
              </p>

              {/* {isSuccess && ( */}
              {/* <> */}
            
              <p className="registerpopup_subhead">
              In case of any questions drop us an email at info@marqeting.in or chat with new community admins at  <span style={{color:"#163e6e"}}>+91 8527436677</span>.

              </p>
              <p className="registerpopup_subhead">
              Thank you for your understanding and continued support.

              </p>
              <p
                className="registerpopup_subhead"
                style={{ fontWeight: "600",marginBottom:"0px"  }}
              >
                Best regards,
              </p>
              <p
                className="registerpopup_subhead"
                style={{ fontWeight: "600",marginTop:"0px" }}
              >
                Team Marqeting
              </p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NewUserOpenPopup;
