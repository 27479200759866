import React, { useState, useEffect, useRef } from "react";
import { PATTERNS } from "../validetion";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  CircularProgress,
  Box,
  Modal,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import SubmitButton from "../coustombutton/SubmitButton";
import {
  useGetAddOrderInfluencers,
  useGetEditOrderCampaignName,
  useGetInfluencer,
  useGetInfluencerUpi,
} from "../customHooks/customHook";
import {
  patchFormData,
  patchFormNewData,
  postFormData,
} from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AddUpiInfluencerPopup from "../adminnote/AddUpiInfluencerPopup";
import InfluencerPopup from "../adminnote/InfluencerPopup";
import CloseIcon from "@mui/icons-material/Close";
// import imageCompression from 'browser-image-compression';
import Resizer from 'react-image-file-resizer';
const EditOrder = () => {
  const { state } = useLocation();
  const orderData = state?.selectedData || {};

  const [formData, setFormData] = useState({
    influncername: {
      value: "",
      isValid: true,
    },
    orderid: {
      value: "" || orderData?.orderId,
      isValid: true,
    },
    ordervalue: {
      value: "" || orderData?.orderValue,
      isValid: true,
    },
    deliverydate: {
      value: orderData?.deliveryDate
        ? new Date(orderData?.deliveryDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
    upiid: {
      value: "",
      isValid: true,
    },
    upiholdername: {
      value: "",
      isValid: true,
    },
  });
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [productName, setProductName] = useState("");
  const [upiIdName, setUpiIdName] = useState({
    upiId: orderData?.upiId,
    holderName: orderData?.holderName,
  });
  const [dummyUpiData, setDummyUpiData] = useState("");
  const [paymentModeName, setPaymentModeName] = useState("");
  const [orderPlatform, setOrderPlatform] = useState("");
  const [orderScreenshotFile, setOrderScreenshotFile] = useState(null);
  const [orderScreenshotFileName, setOrderScreenshotFileName] = useState(null);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const [reviewScreenshotFile, setReviewScreenshotFile] = useState(null);
  const [influncerName, setInfluncerName] = useState(orderData?.influencer);
  const [camapignName, setCamapignName] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [addupiId, setAddupiId] = useState(false);
  const [addupiHolderName, setAddupiHolderName] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultUpiId, setDefaultUpiId] = useState(null);
  // const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    orderData?.orderDate
      ? new Date(orderData?.orderDate).toISOString().split("T")[0]
      : ""
  );
  const [error, setError] = useState(false);
  const addPrefillCampaignDetails = useGetAddOrderInfluencers();
  const [openModalNote, setOpenModalNote] = useState(false);
  const [openModalUpi, setOpenModalUpi] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [triggerRefreshUpi, setTriggerRefreshUpi] = useState(false);
  const [brands, setBrands] = useState(addPrefillCampaignDetails?.allstatus);
  const [selectedBrand, setSelectedBrand] = useState(orderData?.brand);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(orderData?.product);
  const [platforms, setPlatforms] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [submitupiData, setSubmitupiData] = useState(false);
  const [submitInfluemncerData, setSubmitInfluemncerData] = useState(false);
  const [dummyInfluencerData, setDummyInfluencerData] = useState("");
  const [newReviewFile, setNewReviewFile] = useState(null);
  const [initial, setInitial] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const addPrefillCampaignName = useGetEditOrderCampaignName(
    selectedBrand?._id,
    selectedProduct?.value,
    selectedPlatform?.value,
    initial
  );
  const [campaignNamePrefill, setCampaignNamePrefill] = useState(
    orderData?.campaign
  );
  const getInfluencer = useGetInfluencer();
  const getInfluencerUpi = useGetInfluencerUpi(influncerName?._id);
  const [upiDatacombinedData, setUpiDatacombinedData] = useState();
  const [influencerDatacombinedData, setInfluencerDatacombinedData] = useState(
    getInfluencer?.allstatusExport
  );
  const campaignRef = useRef(null);
  const upiRef = useRef(null);

  const handleBrand = (selectedOption) => {
    const selectedBrand = brands.find(
      (brand) => brand._id === selectedOption.value
    );
    setSelectedBrand(selectedBrand);
    // Update products and platforms based on the selected brand
    setProducts(
      selectedBrand.products.map((product) => ({
        value: product._id,
        label: product.productName,
      }))
    );
    setPlatforms(
      selectedBrand.campaignPlatforms.map((platform) => ({
        value: platform._id,
        label: platform.platform,
      }))
    );

    setSelectedProduct(null);
    setSelectedPlatform(null);
  };

  const handleProduct = (selectedOption) => {
    setInitial(true);
    setSelectedProduct(selectedOption);
  };
  useEffect(() => {
    if (addPrefillCampaignDetails?.allstatus) {
      setBrands(addPrefillCampaignDetails?.allstatus);
    }
  }, [addPrefillCampaignDetails?.allstatus]);

  // useEffect(() => {
  //   if (addPrefillCampaignName?.allstatus) {
  //     setCampaignNamePrefill(addPrefillCampaignName?.allstatus[0]);

  //   }
  // }, [addPrefillCampaignName?.allstatus]);

  // useEffect(() => {
  //   if (campaignRef.current) {
  //     campaignRef.current.value = "";
  //   }
  //   setCampaignNamePrefill(null)
  // }, [selectedPlatform, selectedProduct]);

  const handlePlatform = (selectedOption) => {
    setInitial(true);
    setSelectedPlatform(selectedOption);
  };

  useEffect(() => {
    if (orderData && orderData.brand) {
      const selectedBrand = brands.find(
        (brand) => brand._id === orderData?.brand?._id
      );
      setSelectedBrand(selectedBrand);
      if (selectedBrand) {
        setProducts(
          selectedBrand.products.map((product) => ({
            value: product._id,
            label: product.productName,
          }))
        );
        setPlatforms(
          selectedBrand.campaignPlatforms.map((platform) => ({
            value: platform._id,
            label: platform.platform,
          }))
        );

        const selectedProduct = selectedBrand.products.find(
          (product) => product._id === orderData?.product?._id
        );
        setSelectedProduct({
          value: selectedProduct._id,
          label: selectedProduct.productName,
        });

        const selectedPlatform = selectedBrand.campaignPlatforms.find(
          (platform) => platform._id === orderData?.orderPlatform?._id
        );
        setSelectedPlatform({
          value: selectedPlatform._id,
          label: selectedPlatform.platform,
        });
      }
    }
  }, [orderData, selectedBrand, brands]);

  useEffect(() => {
    if (
      selectedPlatform &&
      selectedProduct &&
      addPrefillCampaignName?.error === true
    ) {
      campaignRef.current.value = "";
    }
    // setCampaignNamePrefill(null)
  }, [
    selectedPlatform,
    selectedProduct,
    addPrefillCampaignName?.error === true,
  ]);
  useEffect(() => {
    if (
      addPrefillCampaignName?.allstatus &&
      addPrefillCampaignName?.allstatus.length > 0
    ) {
      setCampaignNamePrefill(addPrefillCampaignName?.allstatus[0]);
    }
    //  else if (addPrefillCampaignName?.allstatus.length === 0 && addPrefillCampaignName?.error && initial) {
    //   console.log("Clearing campaignRef",addPrefillCampaignName?.allstatus);
    //   campaignRef.current.value = "";
    //   setCampaignNamePrefill(null);
    // }
  }, [selectedProduct, selectedPlatform, addPrefillCampaignName, initial]);
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };
  const handleUpiOpenModal = () => {
    setOpenModalUpi(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };
  const handleUpiCloseModal = () => {
    setOpenModalUpi(false);
  };


  // const handleReviewScreenShot = (e) => {
  //   setSelectedImages([
  //     ...selectedImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };
 
  console.log(selectedImages)
 
const handleReviewScreenShot = async (e) => {  
  const newFiles = Array.from(e.target.files);  

  // Function to compress a single image with a target size  
  const compressImage = async (file) => {  
    const maxSize = 40 * 1024; // Target size 40 KB  
    let quality = 90; // Start with a high quality  
    let compressedFile;  

    do {  
      compressedFile = await new Promise((resolve) => {  
        Resizer.imageFileResizer(  
          file,  
          1080, // Max width  
          1080, // Max height  
          'JPEG', // Output type  
          quality, // Quality (0-100)  
          0, // Rotation  
          (uri) => {  
            resolve(uri);  
          },  
          'blob'
        );  
      });  

      // Decrease quality for the next attempt if still too large  
      if (compressedFile.size > maxSize) {  
        quality -= 10; // Decrease quality by 10 for the next attempt  
      }  
    } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0  

    // Return the compressed file along with the original file's name  
    return {  
      file: compressedFile,  
      name: file.name // Original file name  
    };  
  };  

  // Compress files larger than 40 KB  
  const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));  

  // Calculate total size of compressed files  
  const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);  
  const existingFilesTotalSize = selectedImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);  
  const totalSize = newFilesTotalSize + existingFilesTotalSize;  
  // console.log(`${(totalSize / 1024).toFixed(2)} KB`); // Total size in KB, with 2 decimal places  

  // Check if total size exceeds 100 KB  
  if (totalSize > 100 * 1024) {  
    setErrorMessage('Please upload the order screenshot below 100 KB.');  
    e.target.value = null;  
    return;  
  }  

  setErrorMessage('');  

  // Update selected images  
  setSelectedImages([  
    ...selectedImages,  
    ...compressedFiles.map(({ file, name }) => {  
      console.log(`Original file name: ${name}`); // Log original file name to the console
      return {  
        file,  
        url: URL.createObjectURL(file),  
        originalName: name // Save original filename here  
      };  
    }),  
  ]);
  
  e.target.value = null;  
};


  // const handleReviewScreenShot = async (e) => {
  //   const newFiles = Array.from(e.target.files);
  
  //   // Function to compress a single image
  //   const compressImage = async (file) => {
  //     if (file.size <= 100 * 1024) {
  //       // If file size is already less than 100 KB, no need to compress
  //       return file;
  //     }
  
  //     const options = {
  //       maxSizeMB: 0.09, // Target maximum size (500 KB, adjust as needed)
  //       maxWidthOrHeight: 1920, // Resize only for large images (adjust if necessary)
  //       initialQuality: 0.9, // Start with high quality to prevent blurriness
  //       useWebWorker: true, // Use web workers for better performance
  //     };
  
  //     try {
  //       const compressedFile = await imageCompression(file, options);
  //       return compressedFile;
  //     } catch (error) {
  //       console.error("Compression error:", error);
  //       return file; // Return original file if compression fails
  //     }
  //   };
  
  //   // Compress files larger than 100 KB
  //   const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));
  
  //   // Calculate total size of compressed files
  //   const newFilesTotalSize = compressedFiles.reduce((acc, file) => acc + file.size, 0);
  
  //   // Calculate total size of already selected files
  //   const existingFilesTotalSize = selectedImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);
  
  //   // Total size = compressed new files + existing selected files
  //   const totalSize = newFilesTotalSize + existingFilesTotalSize;
  
  //   // Check if total size exceeds 100 KB
  //   if (totalSize > 100 * 1024) {
  //     setErrorMessage('Please upload the order screenshot below 100 KB.');
  //     e.target.value = null;
  //     return;
  //   }
  
  //   setErrorMessage('');
  
  //   // Update selected images
  //   setSelectedImages([
  //     ...selectedImages,
  //     ...compressedFiles.map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  
  //   e.target.value = null;
  // };
  
  const removeImage = (indexToRemove) => {
    setSelectedImages(
      selectedImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeApiImage = (index) => {
    setReviewScreenshotFile(reviewScreenshotFile.filter((_, i) => i !== index));
  };
  const handleUpiId = (selectedOption) => {
    if (selectedOption) {
      const selectedUpiId = selectedOption.value;
      const selectedHolder = getInfluencerUpi.allstatusExport[0]?.upiData.find(
        (item) => item.upiId === selectedUpiId
      );
      if (selectedHolder) {
        setUpiIdName({
          upiId: selectedUpiId,
          holderName: selectedHolder.holderName,
        });
      }
    } else {
      setUpiIdName({
        upiId: "",
        holderName: "",
      });
    }
  };

  useEffect(() => {
    if (dummyUpiData) {
      setUpiIdName(dummyUpiData);
    }
  }, [dummyUpiData]);
  useEffect(() => {
    if (dummyInfluencerData) {
      setInfluncerName(dummyInfluencerData);
    }
  }, [dummyInfluencerData]);

  const handleInfluncer = (selectedOption) => {
    if (selectedOption) {
      setInfluncerName({
        _id: selectedOption.value,
        influencerName: selectedOption.label,
      });
    } else {
      setCamapignName("");
    }
  };
  useEffect(() => {
    setUpiDatacombinedData(getInfluencerUpi?.allstatusExport[0]?.upiData);
  }, [influncerName]);

  useEffect(() => {
    if (upiRef.current && upiRef.current.clearValue) {
      upiRef.current.clearValue();
    }
  }, [influncerName?._id]);
  const handleOrderScreenShot = (e) => {
    setOrderScreenshotFile(e.target.files[0]);
    setOrderScreenshotFileName(e.target.files[0].name);
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setNewReviewFile(fileUrl);
  };
  useEffect(() => {
    if (orderData?.orderScreenshot) {
      setReviewScreenshotFile(orderData?.orderScreenshot);
    }
  }, [orderData]);
  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const refreshUpiData = () => {
    setTriggerRefreshUpi(!triggerRefreshUpi);
  };
  const refreshData = () => {
    setTriggerRefreshUpi(!triggerRefreshUpi);
  };
  // const refreshData = (newInfluencer) => {
  //   setTriggerRefresh(!triggerRefresh);
  //   // setInfluncerName(...influncerName, newInfluencer)
  //   if (newInfluencer) {
  //     setInfluncerName({
  //       influencerName: newInfluencer.data.influencerName,
  //       _id: newInfluencer.data._id,
  //     });
  //   }
  // };

  const createNewInfluencer = () => {
    handleOpenModal();
  };
  const handleDateChange = (event) => {
    const currentDate = new Date();
    // const selectedDate = new Date(event.target.value);

    // if (selectedDate > currentDate) {
    //   setSelectedDate("");
    //   toast.error("Order date should not be a future date.");
    // } else {
    setSelectedDate(event.target.value);
    // }
  };
  const createNewUpi = () => {
    handleUpiOpenModal();
  };
  const handleUpiSubmit = (data) => {
    const upiIdName = data.upiId.toLowerCase();

    // Check if the influencer name already exists in the array
    if (
      getInfluencerUpi?.allstatusExport[0]?.upiData.some(
        (item) => item.upiId.toLowerCase() === upiIdName
      )
    ) {
      toast.error("upiId already exists.");
      // Show an error message or take appropriate action
      return;
    }

    setDummyUpiData(data);
    setUpiDatacombinedData(
      [...(getInfluencerUpi?.allstatusExport[0]?.upiData || []), data],
      data
    );
    setSubmitupiData(true);
  };
  // const handleAddInfluencerSubmit = (data) => {
  //   data._id = Math.random().toString(36).substring(2, 28);

  //   setDummyInfluencerData(data);
  //   setInfluencerDatacombinedData([...getInfluencer?.allstatusExport, data]);
  //   setSubmitInfluemncerData(true);
  // };
  const handleAddInfluencerSubmit = (data) => {
    // const influencerName = data.influencerName;
    // const capitalizedName = influencerName.charAt(0).toUpperCase() + influencerName.slice(1);
    const influencerName = data.influencerName.toLowerCase();

    // Check if the influencer name already exists in the array
    if (
      getInfluencer?.allstatusExport.some(
        (item) => item.influencerName.toLowerCase() === influencerName
      )
    ) {
      toast.error("reviewer name already exists.");
      // Show an error message or take appropriate action
      return;
    }

    data._id = Math.random().toString(36).substring(2, 28);
    setDummyInfluencerData(data);
    setInfluencerDatacombinedData([...getInfluencer?.allstatusExport, data]);
    setSubmitInfluemncerData(true);
  };
  // const generateObjectId = () => {
  //   return [...Array(24)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
  // };
  const handleOrder = (e) => {
    e.preventDefault();
    if (isDisabledNew || OrderIdHypen) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);

    const isFormValid =
      selectedPlatform?.value &&
      selectedProduct?.value &&
      (reviewScreenshotFile?.length > 0 || selectedImages?.length > 0) &&
      upiIdName?.upiId &&
      influncerName?._id &&
      formData.ordervalue.value &&
      formData.ordervalue.isValid &&
      formData.orderid.isValid &&
      formData.orderid.value &&
      formData.deliverydate.value &&
      selectedDate;
    if (isFormValid) {
      const data = {
        orderId: formData.orderid.value,
        orderValue: formData.ordervalue.value,
        influencer: influncerName?._id,
        orderDate: selectedDate,
        deliveryDate:
          formData.deliverydate.value ||
          new Date(orderData?.deliveryDate).toISOString().split("T")[0],
        upiId: upiIdName?.upiId,
        holderName: upiIdName?.holderName,
        orderPlatform: selectedPlatform?.value ? selectedPlatform?.value : "",
        product: selectedProduct?.value ? selectedProduct?.value : "",
        campaign: campaignNamePrefill?._id ? campaignNamePrefill?._id : "",
        // orderScreenshotUrls: reviewScreenshotFile || []
        orderScreenshotUrls: reviewScreenshotFile || [],
      };

      if (dummyInfluencerData) {
        data.influencername = dummyInfluencerData?.influencerName;
      }

      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        // Skip 'orderScreenshotUrls' key since it's an array of objects
        if (key !== "orderScreenshotUrls") {
          newData.append(key, data[key]);
        }
      });
      // // if (reviewScreenshotFile) {
      //   data.orderScreenshotUrls.forEach((item, index) => {
      //     Object.keys(item).forEach((key) => {
      //       newData.append(`orderScreenshotUrls[${index}][${key}]`, item[key]);
      //     });
      //   });
      if (Array.isArray(data.orderScreenshotUrls)) {
        data.orderScreenshotUrls.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            newData.append(`orderScreenshotUrls[${index}][${key}]`, item[key]);
          });
        });
      }
      // if (selectedImages) {
      //   selectedImages.forEach((file, index) => {
      //     newData.append("orderScreenshot", file.file);
      //   });
      // }
      if (selectedImages) {
        selectedImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("orderScreenshot", fileWithOriginalName);
        });
      }
      
      patchFormNewData(
        `${VisitorEndpoint.INFLUENCER_EDIT_ORDER}/${orderData?._id}`,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              toast.success(res.data.message);
              navigate("/influencer/order");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };
  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };

  useEffect(() => {
    setLoading(true);
    setUpiIdName({
      upiId: orderData?.upiId,
      holderName: orderData?.holderName,
    });
    setCampaignNamePrefill(orderData?.campaign);
    setLoading(false);
  }, [orderData]);
  const OrderIdHypen =
    selectedPlatform?.value === "65fae17c70f44cae8e6c063e" &&
    !formData.orderid.value.includes("-");
  return (
    <>
      <InfluencerPopup
        open={openModalNote}
        onClose={handleCloseModal}
        // editState={editState}
        onSuccess={refreshData}
        onFormSubmit={handleAddInfluencerSubmit}
      />
      <AddUpiInfluencerPopup
        open={openModalUpi}
        onClose={handleUpiCloseModal}
        onSuccess={refreshUpiData}
        onFormSubmit={handleUpiSubmit}
      />
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CloseIcon
              onClick={handleCloseZoomModal}
              sx={{
                color: "red",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            />
          </div>
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <div className="container_fluid_new">
        <div className="container_new">
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <div>
                <ActiveLastBreadcrumb
                  links={[
                    { name: "Dashboard", href: "/" },
                    { name: "Order", href: "/influencer/order" },
                    { name: "Edit Order", href: "/influencer/order/edit" },
                  ]}
                />
              </div>
              <h1 className="have_solution_heading">Edit Order</h1>
              <form onSubmit={handleOrder}>
                <Card sx={{ padding: "20px" }}>
                  <CardContent>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Brand Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the brand for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={brands.map((brand) => ({
                              value: brand._id,
                              label: brand.brandName,
                            }))}
                            value={selectedBrand}
                            onChange={handleBrand}
                            name="brand"
                            isClearable={false}
                            isDisabled={
                              orderData && orderData.brand
                                ? !!orderData.brand.brandName
                                : false
                            }
                            key={selectedBrand?.brandName}
                            defaultValue={
                              selectedBrand && selectedBrand?.brandName
                                ? {
                                    label: selectedBrand?.brandName,
                                    value: selectedBrand?._id,
                                  }
                                : ""
                            }
                          />
                          {formSubmitted && !selectedBrand?._id && (
                            <p className="input_text_error">
                              *Please select brand name.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Product Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the product for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={products}
                            value={selectedProduct}
                            onChange={handleProduct}
                            name="product"
                            key={selectedProduct?.label}
                            isClearable={false}
                            defaultValue={
                              selectedProduct && selectedProduct?.label
                                ? {
                                    label: selectedProduct?.label,
                                    value: selectedProduct?.value,
                                  }
                                : ""
                            }
                          />
                          {formSubmitted && !selectedProduct?.value && (
                            <p className="input_text_error">
                              *Please select a product.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Platform
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the platform for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={platforms}
                            value={selectedPlatform}
                            onChange={handlePlatform}
                            name="platform"
                            key={selectedPlatform?.label}
                            isClearable={false}
                            defaultValue={
                              selectedPlatform && selectedPlatform?.label
                                ? {
                                    label: selectedPlatform?.label,
                                    value: selectedPlatform?.value,
                                  }
                                : ""
                            }
                          />
                          {formSubmitted && !selectedPlatform?.value && (
                            <p className="input_text_error">
                              *Please select platform.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Campaign Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the Campaign for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            inputRef={campaignRef}
                            type="text"
                            id="outlined-required"
                            label="First Name"
                            helper
                            disabled
                            value={campaignNamePrefill?.campaignName}
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Reviewer Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the reviewer name who has placed an order.
                        </CustomSubheadingh>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {/* <p
                          onClick={createNewInfluencer}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            alignSelf: "end",
                          }}
                        >
                          Ordering for someone else?
                        </p> */}
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={
                              submitInfluemncerData
                                ? influencerDatacombinedData?.map((item) => ({
                                    // label: item.influencerName,
                                    label: item.influencerName,
                                    value: item._id,
                                  }))
                                : getInfluencer?.allstatusExport.map(
                                    (item) => ({
                                      label: item.influencerName,
                                      value: item._id,
                                    })
                                  )
                            }
                            key={influncerName?.influencerName}
                            value={influncerName}
                            isClearable={false}
                            onChange={handleInfluncer}
                            name="flavor"
                            defaultValue={
                              influncerName && influncerName?.influencerName
                                ? {
                                    label: influncerName?.influencerName,
                                    value: influncerName?._id,
                                  }
                                : ""
                            }
                          />
                          {formSubmitted && !influncerName?._id && (
                            <p className="input_text_error">
                              *Please select an reviewer.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                      {/* </div> */}
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Id
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the Id for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="orderid"
                            type="text"
                            placeholder="Enter Order Id"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            value={formData?.orderid?.value}
                            onChange={(e) => {
                              setValues(e, "orderid", PATTERNS.ORDER_ID);
                            }}
                          />
                          {selectedPlatform?.value ===
                            "65fae17c70f44cae8e6c063e" &&
                            !formData.orderid.value.includes("-") && (
                              <p className="input_text_error">
                                *Please enter the correct Order ID that matches
                                your placed order.
                              </p>
                            )}
                          {formSubmitted && !formData.orderid.value && (
                            <p className="input_text_error">
                              *Please enter the Order Id.
                            </p>
                          )}
                          {formData.orderid.isValid || (
                            <p className="input_text_error">
                              *Please enter a valid Order ID. The '#' character
                              is not allowed.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Value
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the value for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="ordervalue"
                            type="text"
                            onWheel={(e) => e.target.blur()}
                            placeholder="Enter Order Value"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            value={formData?.ordervalue?.value}
                            onKeyDown={(e) => {
                              const allowedKeys = [
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                              ];
                              if (
                                !(
                                  (e.key >= "0" && e.key <= "9") ||
                                  e.key === "." ||
                                  allowedKeys.includes(e.key)
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setValues(
                                e,
                                "ordervalue",
                                PATTERNS.VALUES_DECIMAL
                              );
                            }}
                          />
                          {formSubmitted && !formData.ordervalue.value && (
                            <p className="input_text_error">
                              *Please enter the order amount.
                            </p>
                          )}
                          {formData.ordervalue.isValid || (
                            <p className="input_text_error">
                              *Please enter only numbers.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Date
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the date on which order was made.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="orderdate"
                            type="date"
                            placeholder="Enter Company Legal Address"
                            id="outlined-required"
                            label="First Name"
                            helper
                            value={selectedDate}
                            size="small"
                            onChange={handleDateChange}
                          />
                          {formSubmitted && !selectedDate && (
                            <p className="input_text_error">
                              *Please select the order date.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          {" "}
                          Delivery Date(Estimated)
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the date of product delivery.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="noofcontentpieces"
                            type="date"
                            placeholder="Enter Delivery Date"
                            id="outlined-required"
                            label="First Name"
                            helper
                            value={formData.deliverydate.value}
                            size="small"
                            onChange={(e) => {
                              setValues(e, "deliverydate", null);
                            }}
                          />
                          {formSubmitted && !formData.deliverydate.value && (
                            <p className="input_text_error">
                              *Please enter the delivery date.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Screenshot
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Upload the screenshot of the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleReviewScreenShot}
                            multiple
                            className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                            style={{ color: "transparent" }}
                          />
                          {formSubmitted &&
                            !(
                              selectedImages?.length > 0 ||
                              reviewScreenshotFile?.length > 0
                            ) && (
                              <p className="input_text_error">
                                *Please upload the order screenshot.
                              </p>
                            )}
                            {errorMessage && <p className="input_text_error">{errorMessage}</p>}

                          {selectedImages &&
                            selectedImages.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.url}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.url)
                                    }
                                  />
                                  {/* <p className="ml-2">{image.file.name}</p> */}
                                  <p className="ml-2">{image.originalName}</p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                          {reviewScreenshotFile &&
                            reviewScreenshotFile.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.imageUrl}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.imageUrl)
                                    }
                                  />
                                  <p className="ml-2">{image.image}</p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeApiImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          UPI Id
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the UPI details.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {influncerName?._id && (
                          <p
                            onClick={createNewUpi}
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                              alignSelf: "flex-end",
                            }}
                          >
                            Use Different UPI Id?
                          </p>
                        )}
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={
                              submitupiData
                                ? upiDatacombinedData &&
                                  upiDatacombinedData?.map((item) => ({
                                    label: item.upiId,
                                    value: item.upiId,
                                  }))
                                : getInfluencerUpi?.allstatusExport[0]?.upiData?.map(
                                    (item) => ({
                                      label: item.upiId,
                                      value: item.upiId,
                                    })
                                  )
                            }
                            key={upiIdName?.upiId}
                            value={upiIdName}
                            ref={upiRef}
                            onChange={handleUpiId}
                            name="flavor"
                            isClearable={false}
                            defaultValue={
                              upiIdName && upiIdName?.upiId
                                ? {
                                    label: upiIdName?.upiId,
                                    value: upiIdName?.upiId,
                                  }
                                : ""
                            }
                          />

                          {formSubmitted && !upiIdName?.upiId && (
                            <p className="input_text_error">
                              *Please select the UPI Id.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          UPI Holder Name
                        </CustomRequiredLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="upiholdername"
                            type="text"
                            placeholder="Enter UPI Holder Name"
                            id="outlined-required"
                            label="First Name"
                            helper
                            value={upiIdName.holderName}
                            disabled
                            size="small"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <SubmitButton
                      type={isDisabledNew ? "button" : "submit"}
                      label={isDisabledNew ? "Loading..." : "Submit"}
                      disabled={isDisabledNew || OrderIdHypen}
                    />{" "}
                  </CardContent>
                </Card>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default EditOrder;
// // </>
// {
//   /* )} */
// }
