import React, { useEffect, useState } from "react";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import CardComponent from "../../common/card/CardComponent";
import PaidIcon from "@mui/icons-material/Paid";
import { useGetAllStatsInfluencers } from "../../component/customHooks/customHook";

import { Order, PaymentBlackHeader } from "../../component/IconsImport";
import CampaignIcon from "@mui/icons-material/Campaign";
import NewUserOpenPopup from "../../common/modalZoom/NewUserOpenPopup";

const MarqDashboardComponent = () => {
  const {
    allstatus: AllInfluencersStats,
    loading: AllInfluencersStatsLoading,
  } = useGetAllStatsInfluencers();
  const [isPopupOpen, setIsPopupOpen] = useState(true);

  // console.log(AllInfluencersStats);

  return (
    <>
          <NewUserOpenPopup
    open={isPopupOpen}
    navigateToHome={() => {
          setIsPopupOpen(false); 
        }}

  />
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            <ActiveLastBreadcrumb links={[{ name: "Dashboard", href: "/" }]} />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>
                Welcome{" "}
                {AllInfluencersStats[0]?.influencerName &&
                  AllInfluencersStats[0]?.influencerName
                    .charAt(0)
                    .toUpperCase() +
                    AllInfluencersStats[0]?.influencerName.slice(1)}
              </p>
            </div>
          </div>
          <div className="card_container">
            <CardComponent
              logoColor="green"
              icon={CampaignIcon}
              data={AllInfluencersStats[0]?.totalParticipatedCampaign}
              heading="Participated Campaigns"
              loading={AllInfluencersStatsLoading}
              width="250px"
            />
            <CardComponent
              logoColor="purple"
              icon={CampaignIcon}
              data={AllInfluencersStats[0]?.totalActiveCampaigns}
              heading="Active Campaigns"
              loading={AllInfluencersStatsLoading}
              width="250px"
            />

            <CardComponent
              logoColor="purple"
              image={Order}
              data={AllInfluencersStats[0]?.totalPlacedOrders}
              heading="Placed Orders"
              loading={AllInfluencersStatsLoading}
              width="250px"
            />
            <CardComponent
              logoColor="pink"
              image={Order}
              heading="Completed Orders"
              width="250px"
              data={AllInfluencersStats[0]?.totalCompletedOrders}
              loading={AllInfluencersStatsLoading}
            />
            <CardComponent
              logoColor="purple"
              image={Order}
              heading="Reviewed Orders"
              data={AllInfluencersStats[0]?.totalReviewed}
              width="250px"
              loading={AllInfluencersStatsLoading}
            />
            <CardComponent
              logoColor="purple"
              image={Order}
              heading="Review Pending Orders"
              data={AllInfluencersStats[0]?.totalReviewPending}
              width="250px"
              color={"red"}
              loading={AllInfluencersStatsLoading}
            />
            <CardComponent
              logoColor="pink"
              image={PaymentBlackHeader}
              heading="Reimbursed Amount"
              data={AllInfluencersStats[0]?.totalReimbursment}
              width="250px"
              color={"green"}
              loading={AllInfluencersStatsLoading}
            />
            <CardComponent
              logoColor="green"
              image={Order}
              heading="Returned/Cancelled &nbsp;/Invalid Orders"
              data={AllInfluencersStats[0]?.totalCancelledReturnedInvalidOrders}
              width="250px"
              loading={AllInfluencersStatsLoading}
            />

            <CardComponent
              logoColor="blue"
              image={PaymentBlackHeader}
              data={AllInfluencersStats[0]?.totalReimbursmentPendingOrders}
              heading="Reimbursement Pending Orders"
              color={"red"}
              loading={AllInfluencersStatsLoading}
              width="250px"
            />

            <CardComponent
              logoColor="blue"
              image={PaymentBlackHeader}
              data={AllInfluencersStats[0]?.totalPendingReimbursement}
              heading="Pending Reimbursement Amount"
              color={"red"}
              loading={AllInfluencersStatsLoading}
              width="250px"
            />

            {/* <CardComponent
              logoColor="blue"
              image={PaymentBlackHeader}
              data={AllInfluencersStats[0]?.totalExhaustedCost}
              heading="Exhausted Order Cost"
              loading={AllInfluencersStatsLoading}
              width="250px"
            /> */}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default MarqDashboardComponent;
